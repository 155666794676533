import { ColumnInterface } from '../../common/table/Table';

export const columns: Array<ColumnInterface> = [
  { label: 'Username', key: 'username' },
  { label: 'First Name', key: 'firstName', textAlign: 'right' },
  { label: 'Last Name', key: 'lastName', textAlign: 'right' },
  { label: 'Phone Number', key: 'phone', textAlign: 'right' },
  { label: 'Email', key: 'email', textAlign: 'right' },
  { label: 'Total Points', key: 'totalPoints', textAlign: 'right' },
  { label: 'Membership Type', key: 'membershipType', textAlign: 'right' },
  {
    label: 'Referred By',
    key: 'referredBy',
    textAlign: 'right',
    sorting: (direction: string) => ({
      ReferredByReferral: {
        RefferedByUser: {
          firstName: direction,
        },
      },
    }),
    rowRender: (row) =>
      `${row.ReferredByReferral?.RefferedByUser?.firstName ?? ''} ${
        row.ReferredByReferral?.RefferedByUser?.lastName ?? ''
      }`,
  },
  {
    label: 'Total Referrals',
    key: 'total',
    textAlign: 'right',
    sorting: (direction: string) => ({
      Referrals: {
        _count: direction,
      },
    }),
    rowRender: (row) => `${row.Referrals?.length ?? 0}`,
  },
  // { label: 'Is Email verified', key: 'isEmailVerified' },
  // { label: 'Is Phone verified', key: 'isPhoneVerified' },
];

export const sortByOptions = [
  { label: 'TotalPoints - Ascending', value: 'totalPoints asc' },
  { label: 'TotalPoints - Descending', value: 'totalPoints desc' },
];

import { useQuery } from "@apollo/client";
import { Box, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { Item } from "../../styles/common";
import { USER_DETAILS_PAGE } from "./userDetails.queries";

export const UserDetails: React.FC<any> = (): JSX.Element => {
  const params = useParams();

  const {data, loading, error} = useQuery(USER_DETAILS_PAGE, {
    variables: {
      id: Number(params.userId),
    }
  });

  if (loading) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (<div>{error.message}</div>);
  }

  const userDetails = data.fetchUserDetails;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Item>
          <Typography variant="h3">
            {userDetails.firstName} {userDetails.lastName}
          </Typography>
        </Item>
      </Grid>
      <Grid item xs={7}>
        <Item>
          <UserInfo userDetails={userDetails} />
        </Item>
      </Grid>
      <Grid item xs={2}>
        <Item>
        {userDetails.ProfileImage?.url && (
                <div style={{ maxHeight: '100px', textAlign: 'center' }}>
                  <img alt="profile" src={userDetails.ProfileImage.url} height={100} />
                </div>
              )}
        </Item>
      </Grid>
      <Grid item xs={3}>
        <Item>
          <Typography variant="h5">Committees</Typography>
          <ul style={{listStylePosition: 'inside'}}>
            {userDetails.CommunityUser.map((c: any) => (<li key={c.streamChannelId}>{c.streamChannelId.replace('livestream:', '').split('_')[0]}</li>))}
          </ul>
        </Item>
      </Grid>
      <Grid item xs={6}>
        <Item>
          <Typography variant="h5">Referred users</Typography>
          <Referrals referrals={userDetails.Referrals} />
        </Item>
      </Grid>
      <Grid item xs={6}>
        <Item>
          <Typography variant="h5">Badges</Typography>
          <Initiatives initiativeProgress={userDetails.InitiativeProgress} />
        </Item>
      </Grid>
      <Grid item xs={12}>
        <Item>
          <Typography variant="h5">Recommended businesses</Typography>
          <RecommendedBusinesses recommendedBusinesses={userDetails.BusinessRecommendations} />
        </Item>
      </Grid>
    </Grid>
  );
};

interface UserInfoProps {
  userDetails: any;
}

const UserInfo: React.FC<UserInfoProps> = ({userDetails}: UserInfoProps): JSX.Element => {
  return (
    <Grid container>
      <Grid item xs={3}>
        <strong>id</strong>
      </Grid>
      <Grid item xs={9}>
        {userDetails.id}
      </Grid>

      <Grid item xs={3}>
        <strong>uid</strong>
      </Grid>
      <Grid item xs={9}>
        {userDetails.uid}
      </Grid>

      <Grid item xs={3}>
        <strong>Name</strong>
      </Grid>
      <Grid item xs={9}>
        {userDetails.firstName} {userDetails.lastName}
      </Grid>

      <Grid item xs={3}>
        <strong>Username</strong>
      </Grid>
      <Grid item xs={9}>
        {userDetails.username}
      </Grid>

      <Grid item xs={3}>
        <strong>Email</strong>
      </Grid>
      <Grid item xs={9}>
        {userDetails.email}
      </Grid>

      <Grid item xs={3}>
        <strong>Phone</strong>
      </Grid>
      <Grid item xs={9}>
        {userDetails.phone}
      </Grid>

      <Grid item xs={3}>
        <strong>User Level</strong>
      </Grid>
      <Grid item xs={9}>
        {userDetails.userLevel}
      </Grid>

      <Grid item xs={3}>
        <strong>Gender</strong>
      </Grid>
      <Grid item xs={9}>
        {userDetails.gender}
      </Grid>

      <Grid item xs={3}>
        <strong>Total Points</strong>
      </Grid>
      <Grid item xs={9}>
        {userDetails.totalPoints}
      </Grid>

      <Grid item xs={3}>
        <strong>Membership type</strong>
      </Grid>
      <Grid item xs={9}>
        {userDetails.membershipType}
      </Grid>

      <Grid item xs={3}>
        <strong>Country</strong>
      </Grid>
      <Grid item xs={9}>
        {userDetails.regionCountry} ({userDetails.regionCountryCode})
      </Grid>

      <Grid item xs={3}>
        <strong>State</strong>
      </Grid>
      <Grid item xs={9}>
        {userDetails.regionState} ({userDetails.regionStateCode})
      </Grid>

      <Grid item xs={3}>
        <strong>City</strong>
      </Grid>
      <Grid item xs={9}>
        {userDetails.regionCity}
      </Grid>

      <Grid item xs={3}>
        <strong>Street address</strong>
      </Grid>
      <Grid item xs={9}>
        {userDetails.streetAddress}
      </Grid>

    </Grid>
  )
};

interface ReferralsProps {
  referrals: any;
}

const Referrals: React.FC<ReferralsProps> = ({referrals}: ReferralsProps): JSX.Element => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>id</TableCell>
            <TableCell>Username</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {referrals.map(({ ReferredToUser: r }: any) => (
            <TableRow key={r.id}>
              <TableCell>{r.id}</TableCell>
              <TableCell>{r.username}</TableCell>
              <TableCell>{r.firstName} {r.lastName}</TableCell>
              <TableCell>{r.email}</TableCell>
              <TableCell>{r.phone}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface InitiativesProps {
  initiativeProgress: any;
}

const Initiatives: React.FC<InitiativesProps> = ({initiativeProgress}: InitiativesProps): JSX.Element => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Initiative</TableCell>
            <TableCell>Steps</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {initiativeProgress.map((a: any) => (
            <TableRow key={a.id}>
              <TableCell>{a.Initiative.InitiativeTranslation[0].title}</TableCell>
              <TableCell>{a.steps} / {a.Initiative.steps}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface RecommendedBusinessesProps {
  recommendedBusinesses: any;
}

const RecommendedBusinesses: React.FC<RecommendedBusinessesProps> = ({recommendedBusinesses}: RecommendedBusinessesProps): JSX.Element => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>id</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>Recommended at</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {recommendedBusinesses.map((r:any) => (
            <TableRow key={r.id}>
              <TableCell>{r.id}</TableCell>
              <TableCell>{r.Business.name}</TableCell>
              <TableCell>{r.Business.BusinessAddresses[0].formattedAddress}</TableCell>
              <TableCell>{new Date(r.createdAt).toLocaleDateString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
import { gql } from '@apollo/client';

export const USERS = gql`
  query fetchUsers(
    $filter: UserFilterInputType
    $pagination: PaginationInputType
    $orderBy: UserOrderByWithRelationInput
  ) {
    fetchUsers(filter: $filter, pagination: $pagination, orderBy: $orderBy) {
      users {
        id
        username
        firstName
        lastName
        email
        phone
        totalPoints
        isNotificationEnabled
        membershipType
        isEmailVerified
        isPhoneVerified
        City {
          name
        }
        Country {
          name
        }
        State {
          name
        }
        ReferredByReferral {
          RefferedByUser {
            firstName
            lastName
            username
          }
        }
        Referrals {
          id
        }
      }
      total
    }
  }
`;

export const USER_DETAILS = gql`
  query fetchUserDetails($id: Int!) {
    fetchUserDetails(id: $id) {
      id
      uid
      username
      firstName
      lastName
      email
      phone
      userLevel
      gender
      totalPoints
      membershipType
      streetAddress
      tShirtSize
      role
      regionCity
      regionState
      regionStateCode
      regionCountry
      regionCountryCode
      ProfileImage {
        url
      }
    }
  }
`;

export const COUNTRY_FILTER_AUTOCOMPLETE = gql`
  query countryAutocomplete($filter: String) {
    getCountriesAutocomplete(filter: $filter) {
      regionCountry
    }
  }
`;

export const STATE_FILTER_AUTOCOMPLETE = gql`
  query stateFilterAutocomplete($filter: String) {
    getStatesAutocomplete(filter: $filter) {
      regionState
      regionCountry
    }
  }
`;

export const CITY_FILTER_AUTOCOMPLETE = gql`
  query cityFilterAutocomplete($filter: String) {
    getCitiesAutocomplete(filter: $filter) {
      regionCity
      regionState
      regionCountry
    }
  }
`;

export const CITY_GOOGLE_PLACE_AUTOCOMPLETE = gql`
  query cityGooglePlaceAutocomplete($filter: String) {
    getCitiesGooglePlaceAutocomplete(filter: $filter) {
      placeId
      description
    }
  }
`;

export const FETCH_CITY_GOOGLE_PLACE = gql`
  query fetchCityGooglePlace($placeId: String!) {
    fetchCityGooglePlace(placeId: $placeId) {
      regionCity
      regionState
      regionStateCode
      regionCountry
      regionCountryCode
    }
  }
`;

export const USER_FIELDS = gql`
  query {
    fetchUserFields {
      fields {
        name
        type
        kind
        isReference
      }
    }
  }
`;

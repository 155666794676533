import { useMutation, useQuery } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as z from 'zod';
import { useToast } from "../../common/hooks/useToast";
import { ColumnInterface, Table } from "../../common/table/Table";
import { Item } from "../../styles/common";
import { Filter, UserFilters } from "../users/UserFilters";
import { CREATE_NOTIFICATION } from "./notification.mutations";
import { FETCH_USERS_WITH_TOKEN } from "./notification.queries";

const columns: Array<ColumnInterface> = [
  { label: 'Username', key: 'username' },
  { label: 'First Name', key: 'firstName', textAlign: 'right' },
  { label: 'Last Name', key: 'lastName', textAlign: 'right' },
  { label: 'Phone Number', key: 'phone', textAlign: 'right' },
  { label: 'Email', key: 'email', textAlign: 'right' },
];

const perPage = Number(process.env.REACT_APP_PAGINATION_ROWS_PER_PAGE ?? 5);

const schema = z.object({
  title: z.string().min(1, { message: 'Required' }),
  body: z.string().min(1, { message: 'Required' }),
  imageUrl: z.string(),
  screenLink: z.string(),
});

type SchemaProps = z.infer<typeof schema>;

export const NotificationWithFilters : React.FC = (): JSX.Element => {
  let variables = useRef<any>({
    "pagination": {
        "skip": 0,
        "take": perPage
    }
  });

  const { data, loading, error, refetch } = useQuery(FETCH_USERS_WITH_TOKEN, { variables });

  const [page, setPage] = useState(0);
  const { success } = useToast();
  const [rowsPerPage, setRowsPerPage] = useState(perPage);
  const [notificationSent, setNotificationSent] = useState(false);

  useEffect(() => {
    variables.current = {
        pagination: {
            take: rowsPerPage,
            skip: page * rowsPerPage,
        }
    };

    refetch(variables.current);
}, [page, refetch, rowsPerPage]);

  const onFilter = useCallback((filter: Filter) => {
    variables.current = {
      ...variables.current,
      filter: {
          fieldFilter: {
              filters: filter.filters,
              operator: filter.operator,
          },
          hasReferred: filter.usersThatHaveReferred,
          hasRecommendedABusiness: filter.usersThatHaveRecommended,
          hasRecommendedSameBusiness: filter.usersThatHaveRecommendedSameBusiness,
          visitedPage: filter.visitedPage,
          hasSharedReferralLink: filter.hasSharedReferralLink,
      },
  };
    refetch(variables.current);
  }, [refetch]);

  const [createNotification, result] = useMutation(CREATE_NOTIFICATION, {
    errorPolicy: "all",
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SchemaProps>({
    resolver: zodResolver(schema),
  });

  const submitData = useCallback((values: any) => {
    console.log('submit', values, variables.current);
    createNotification({
      variables: {
        filter: variables.current.filter,
        input: {
          title: values.title,
          body: values.body,
          image: values.imageUrl,
          screenLink: values.screenLink,
        }
      }
    });
    setNotificationSent(true);
  }, [createNotification]);

  useEffect(() => {
    if (result.data && notificationSent) {
      success("Notification sent successfully.");
      reset();
      setNotificationSent(false);
    }
  }, [notificationSent, reset, result.data, success]);

  if (loading) return <h4>Loading...</h4>;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Item>
            <Typography sx={{ m: 2 }} variant="h4">Notifications</Typography>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                Select users. Current selection: {data?.fetchUsersWithTokens?.total ?? 0}
              </AccordionSummary>
              <AccordionDetails>
                <UserFilters onFilter={onFilter} />
                <Table
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  loading={loading}
                  columns={[
                      ...columns,
                    ]}
                  rows={data.fetchUsersWithTokens.users}
                  total={data.fetchUsersWithTokens.total}
                />
              </AccordionDetails>
            </Accordion>
            <form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit((d) => submitData(d))}
            >
              <Stack spacing={2}>
                <Typography sx={{ m: 2 }} variant="h6">Notification</Typography>
                <TextField label="Title" {...register('title')} error={!!errors.title} helperText={errors.title?.message}  />
                <TextField label="Body" {...register('body')} error={!!errors.body} helperText={errors.body?.message} multiline minRows={3} />
                <TextField label="Image url" {...register('imageUrl')} error={!!errors.imageUrl} helperText={errors.imageUrl?.message}  />
                <TextField label="Screen Link" {...register('screenLink')} error={!!errors.screenLink} helperText={errors.screenLink?.message}  />
              </Stack>

              <div style={{marginTop: '1em'}}>
                <Button size="large" sx={{ marginRight: 3 }} type="submit" variant="contained" color="success">
                    Send
                </Button>
              </div>
            </form>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
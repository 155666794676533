import { gql } from '@apollo/client';

export const FETCH_USERS_WITH_TOKEN = gql`
  query fetchUsersWithTokens(
    $pagination: PaginationInputType
    $filter: UserFilterInputType
  ) {
    fetchUsersWithTokens(filter: $filter, pagination: $pagination) {
      users {
        id
        email
        phone
        firstName
        lastName
      }
      total
    }
  }
`;

import { gql } from '@apollo/client';

export const CREATE_NOTIFICATION = gql`
  mutation createNotification(
    $input: NotificationInput!
    $filter: UserFilterInputType
  ) {
    createNotification(input: $input, filter: $filter) {
      id
      status
      createdAt
    }
  }
`;

import { styled } from "@mui/material";
import { IconType } from "react-icons";
import { FaBusinessTime, FaUsers } from "react-icons/fa";
import { TbMessage } from "react-icons/tb";
import { useLocation } from "react-router-dom";
import {
  ActiveLink,
  SidebarContainer,
  StyledNavLink
} from "./sidebar.elements";

const SideLink = styled("div")`
  display: flex;
  align-items: center;
`;

interface ItemInterface {
  link: string;
  icon: IconType;
  label: string;
}

// const SIDE_NAV_WIDTH = 280;

const items: Array<ItemInterface> = [
  {
    label: "Users",
    link: "/users",
    icon: FaUsers,
  },
  // {
  //   label: 'Achievements',
  //   link: '/achievements',
  //   icon: FaPeopleCarry,
  // },
  {
    label: "Businesses",
    link: "/businesses",
    icon: FaBusinessTime,
  },
  {
    label: "Notification",
    link: "/notification-new",
    icon: TbMessage,
  },
];

//   TODO: Better type
export const Sidebar = ({ width }: { width: string | number }) => {
  const { pathname } = useLocation();

  const showLinks = (items: Array<ItemInterface>) => {
    return items.map((item: ItemInterface, index: number) => {
      const Link = pathname === item.link ? ActiveLink : StyledNavLink;
      const Icon = item.icon;

      return (
        <div key={index}>
          <Link to={item.link} key={index}>
            <SideLink>
              <Icon color="red" size={20} />
              <span style={{ marginLeft: "10px" }}>{item.label}</span>
            </SideLink>
          </Link>
        </div>
      );
    });
  };

  return (
    <SidebarContainer width={width}>
      <div>
        {/* <LogoContainer>
              <i className="bx bx-layer nav_logo-icon">Admin App</i>
            </LogoContainer> */}

        {showLinks(items)}
      </div>
    </SidebarContainer>
  );
};

import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: Int!
    $userUpdateInput: UserUpdateInput!
    $file: Upload
  ) {
    updateUser(id: $id, userUpdateInput: $userUpdateInput, file: $file) {
      id
    }
  }
`;

import {
  Auth,
  ConfirmationResult,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  User,
} from 'firebase/auth';
import { useCallback, useState } from 'react';

let recaptchaVerifier: RecaptchaVerifier;
let confirmationResult: ConfirmationResult;

export enum PhoneSignInStatus {
  initial,
  codeSent,
  verifying,
}

export enum PhoneSignInError {
  smsSendingFailure,
  otpVerificationFailure,
  captchaFailure,
}

export const useSignInWithPhoneNumber = (
  auth: Auth,
  submitButtonId: string,
): [
  () => void,
  (phoneNumber: string) => void,
  (code: string) => void,
  () => void,
  PhoneSignInStatus,
  PhoneSignInError | null,
  User | null,
] => {
  const [signInStatus, setSignInStatus] = useState(PhoneSignInStatus.initial);
  const [loggedInUser, setLoggedInUser] = useState<User | null>(null);
  const [error, setError] = useState<PhoneSignInError | null>(null);

  const initialize = useCallback(() => {
    recaptchaVerifier = new RecaptchaVerifier(auth, submitButtonId, {
      size: 'invisible',
      'error-callback': () => {
        console.log('captcha error');
        setError(PhoneSignInError.captchaFailure);
      },
    });
  }, [auth, submitButtonId]);

  const signInWithPhone = useCallback(
    (phoneNumber: string) => {
      signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier)
        .then((result) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          setSignInStatus(PhoneSignInStatus.codeSent);
          confirmationResult = result;
          // ...
        })
        .catch((error) => {
          console.log('SMS sending error', error);
          setError(PhoneSignInError.smsSendingFailure);
          // Error; SMS not sent
          // ...
          setSignInStatus(PhoneSignInStatus.initial);
          if (recaptchaVerifier) {
            recaptchaVerifier.clear();
          }
          initialize();
        });
    },
    [auth, initialize],
  );

  const verifyCode = useCallback(
    (code: string) => {
      setSignInStatus(PhoneSignInStatus.verifying);
      confirmationResult
        .confirm(code)
        .then((result) => {
          // User signed in successfully.
          const user = result.user;
          // console.log('user', user);
          setLoggedInUser(user);
        })
        .catch((error) => {
          // User couldn't sign in (bad verification code?)
          // ...
          setError(PhoneSignInError.otpVerificationFailure);
          setSignInStatus(PhoneSignInStatus.initial);
          if (recaptchaVerifier) {
            recaptchaVerifier.clear();
          }
          initialize();
          // console.log('verify error', error);
        });
    },
    [initialize],
  );

  const reset = useCallback(() => {
    setSignInStatus(PhoneSignInStatus.initial);
    if (recaptchaVerifier) {
      recaptchaVerifier.clear();
    }
    setError(null);
    initialize();
  }, [initialize]);

  return [
    initialize,
    signInWithPhone,
    verifyCode,
    reset,
    signInStatus,
    error,
    loggedInUser,
  ];
};

import { gql } from '@apollo/client';

export const USER_DETAILS_PAGE = gql`
  query fetchUserDetails($id: Int!) {
    fetchUserDetails(id: $id) {
      id
      uid
      username
      firstName
      lastName
      email
      phone
      userLevel
      gender
      totalPoints
      membershipType
      streetAddress
      tShirtSize
      role
      regionCity
      regionState
      regionStateCode
      regionCountry
      regionCountryCode
      ProfileImage {
        url
      }
      CommunityUser {
        streamChannelId
      }
      Referrals {
        ReferredToUser {
          id
          username
          firstName
          lastName
          email
          phone
        }
      }
      InitiativeProgress {
        id
        steps
        Initiative {
          steps
          InitiativeTranslation {
            locale
            title
          }
        }
      }
      BusinessRecommendations {
        createdAt
        Business {
          id
          name
          BusinessAddresses {
            formattedAddress
            country
            state
            city
          }
        }
      }
    }
  }
`;
